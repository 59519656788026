import React from 'react';
import BEMHelper from '@ynap/bem-helper';
import FounditList from '../Foundit/FounditList/FounditList';

interface FounditWrapperProps {
    content: {
        teaserTitle: string;
        href: string;
        picturesAndMedia: {
            type: string;
            imageUrl: string;
            height: number;
            width: number;
        }[];
    }[];
    urlConfig: { [key: string]: object };
    locale: string;
    internal_urls: string[];
    messages: string;
}

const bem = new BEMHelper('FounditWrapper');

const FounditWrapper = ({ content, urlConfig, locale, internal_urls, messages }: FounditWrapperProps) => {
    return (
        <div className={bem()}>
            <FounditList
                slideModifiers={{ 'col-md': true }}
                item={{ items: content }}
                urlConfig={urlConfig}
                locale={locale}
                internal_urls={internal_urls}
                isFounditContent
                messages={messages}
            />
        </div>
    );
};

export default React.memo(FounditWrapper);
