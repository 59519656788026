import React from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { linkRenderer } from '@ynap/router';
import { Picture } from '@ycos/picture';

const bem = new BEMHelper('ContentItem');

const ContentItem = (props) => {
    const { scrollableCarousel, contentItem, locale, urlConfig = {}, internal_urls = [], onClick, imageQualityPercentage = 80 } = props;
    if (!contentItem) return null;

    const { teaserTitle, href, picturesAndMedia: pictures, reloadPage = false } = contentItem;
    const scrollableCarouselModifier = scrollableCarousel || '';
    const className = bem('itemWrapper', scrollableCarouselModifier);

    const content = (
        <>
            {pictures && <Picture images={pictures} lazy={false} imagesphere={{ quality: imageQualityPercentage }} fetchPriority="high" />}
            <p className={bem('title')}>{teaserTitle}</p>
        </>
    );

    const withLink = ({ children, onClick }) => linkRenderer(urlConfig, internal_urls, locale, href, children, onClick, className, reloadPage);

    return <>{href ? withLink({ children: content, onClick: () => onClick() }) : content}</>;
};

ContentItem.propTypes = {
    scrollableCarousel: PropTypes.string,
    contentItem: PropTypes.object,
    locale: PropTypes.string,
    urlConfig: PropTypes.object,
    imageQualityPercentage: PropTypes.string,
    internal_urls: PropTypes.array,
    onClick: PropTypes.func.isRequired,
};

export default ContentItem;
