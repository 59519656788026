import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { BackgroundPicture } from '@ycos/picture';
import { useConfig } from '@ynap/immutables-provider';
import { ValueTransition } from '@ynap/value-transition';
import { withAnalytics, safeBuildEventObj } from '@ynap/analytics';
import { ratioToPadding } from '@ynap/fela';
import { linkRenderer } from '@ynap/router';
import track from 'react-tracking';
import { trackCoremediaModuleClick } from '../../analytics/coremedia';

const FooterBlockContent = ({ item, analytics, tracking, urlConfig, internal_urls, locale }) => {
    if (!item) return;

    const bem = new BEMHelper('FooterBlock');
    const { teaserTitle, teaserTextPlain, subTitlePlain, href, picturesAndMedia, imageQualityPercentage = 80, anchorpoint = '' } = item;

    const {
        head: { aspectRatio: { articleImage, articleImagePhone } = {} },
    } = useConfig();

    const [track, setTracking] = useState(false);

    useEffect(() => {
        if (window && track) {
            tracking.trackEvent(safeBuildEventObj(trackCoremediaModuleClick, { analytics, href }));
            window.location.assign(href);
        }
    }, [track]);

    const forceAspectRatioDesktop = ratioToPadding(articleImage);
    const forceAspectRatioMobile = ratioToPadding(articleImagePhone);

    const content = (
        <>
            <BackgroundPicture
                images={picturesAndMedia}
                forceAspectRatioDesktop={forceAspectRatioDesktop}
                forceAspectRatioMobile={forceAspectRatioMobile}
                imagesphere={{ quality: imageQualityPercentage }}
                additionalStyles={{ '& div': { height: 'auto' } }}
            />
            <div className={bem('content')}>
                <ValueTransition component="h3" className={bem('title')}>
                    {teaserTitle}
                </ValueTransition>
                {teaserTextPlain && (
                    <ValueTransition component="p" className={bem('copy')}>
                        {teaserTextPlain}
                    </ValueTransition>
                )}
                {href && <p className={bem('link')}>{subTitlePlain}</p>}
            </div>
        </>
    );

    const link =
        href &&
        linkRenderer(urlConfig, internal_urls, locale, `${href}${anchorpoint}`, content, (event) => {
            event.preventDefault();
            setTracking(true);
        });

    return <div className={bem()}>{href ? link : content}</div>;
};

const FooterBlock = track()(withAnalytics(FooterBlockContent));

FooterBlock.layoutVariant = ['yos-plp-footer-block'];

FooterBlock.propTypes = {
    item: PropTypes.shape({
        teaserTitle: PropTypes.string,
        teaserTextPlain: PropTypes.string,
        subTitlePlain: PropTypes.string,
        href: PropTypes.string,
        imagePath: PropTypes.string,
        imageWidth: PropTypes.string,
        imageHeight: PropTypes.string,
    }),
};

export default FooterBlock;
