import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import BEMHelper from '@ynap/bem-helper';
import { withAnalytics, safeBuildEventObj } from '@ynap/analytics';
import { trackFounditModuleClick } from '../../analytics/foundit';
import { linkRenderer } from '@ynap/router';
import track from 'react-tracking';

const bem = new BEMHelper('FounditList');

const FounditList = (props) => {
    const { item = {}, locale, urlConfig = {}, internal_urls = [], onClick = () => {}, analytics, tracking, isFounditContent, messages } = props;
    const wrapperRef = useRef(null);

    const withLink = ({ children, href }) => linkRenderer(urlConfig, internal_urls, locale, href, children);

    const content = (item, index) => (
        <li
            onClick={() => {
                tracking.trackEvent(
                    safeBuildEventObj(
                        isFounditContent && trackFounditModuleClick,
                        isFounditContent ? { analytics, item, totalLinks: props.item.items.length, linkPosition: index } : { analytics, href: item.href },
                    ),
                );
                return onClick;
            }}
            className={bem('title')}
        >
            {item.teaserTitle}
        </li>
    );

    return (
        <section className={bem(null)}>
            <div className={bem('itemsContainer')} ref={wrapperRef}>
                {<div className={bem('featuredCategories')}>{messages?.featuredCategories?.heading()}</div>}
                <ul className={bem('itemsWrapper')}>
                    {item.items?.map((item, index) => (item.href ? withLink({ children: content(item, index), href: item.href }) : content(item, index)))}
                </ul>
            </div>
        </section>
    );
};

const FounditListContainer = track()(withAnalytics(FounditList));

FounditList.defaultProps = {
    isFounditContent: false,
};

FounditList.propTypes = {
    content: PropTypes.object,
    locale: PropTypes.string,
    urlConfig: PropTypes.object,
    internal_urls: PropTypes.array,
    imageQualityPercentage: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    isFounditContent: PropTypes.bool,
};

export default FounditListContainer;
