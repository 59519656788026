/* istanbul ignore file */
import { DetailsAndCareTitleDefault, DetailsAndCareTitleWatches } from '@ynap/product-details-page';
import { formatMeasurementMM, formatMeasurementCM } from '@ynap/format-measurement';
import { SizeGuideWatches, SizeGuideDefault } from '@ynap/product-details-page/src/SizeChart/features.js';
import { showSizeChartDefault, showSizeChartWatches } from '@ynap/product-details-page/src/SizeChartLink/features.js';

// precedence is first to match so put generic stuff last.
export default {
    featureSelection: {
        accordionTitle: [
            {
                categories: ['category_30139', 'MRP-4584'],
                feature: {
                    Component: DetailsAndCareTitleWatches,
                },
            },
            {
                feature: { Component: DetailsAndCareTitleDefault },
            },
        ],
        emailUsCTA: [{ feature: { Component: null } }],
        formatMeasurement: [{ categories: ['category_30139'], feature: formatMeasurementMM }, { feature: formatMeasurementCM }],
        collapseSizeAndFit: [{ categories: ['category_30139'], feature: { bypassControls: true } }, { feature: { bypassControls: false } }],
        showSizeChart: [{ categories: ['category_30139', 'MRP-4584'], feature: showSizeChartWatches }, { feature: showSizeChartDefault }],
        showHMAM: [
            { categories: ['MRP-4584'], feature: { sizeGuideTitle: SizeGuideWatches, withOverlay: false, coreMediaKey: 'items', componentKey: 'pdp-hmam-lw' } },
            { feature: { sizeGuideTitle: SizeGuideDefault, withOverlay: true, coreMediaKey: 'picturesAndMedia', componentKey: 'pdp-hmam' } },
        ],
        hideSizeSelect: [{ categories: ['MRP-4584'], feature: true }],
        hideColour: [{ categories: ['MRP-4584'], feature: true }],
        hideSizeAndFit: [{ categories: ['MRP-4584'], feature: true }],
        isWatchExperts: [{ categories: ['MRP-4584', 'NAP-3965-3966'], feature: true }],
        ymalFirst: [{ categories: ['MRP-5-997'], feature: true }],
        hideOneSize: [
            {
                categories: [
                    'MRP-1-1016',
                    'MRP-1-1012',
                    'MRP-5623',
                    'MRP-1-1022',
                    'MRP-1-24',
                    'MRP-1-1019',
                    'MRP-1-931',
                    'MRP-1-27',
                    'MRP-1-1011',
                    'MRP-1780',
                    'MRP-3151',
                ],
                feature: true,
            },
        ],
    },
};
